<template>
  <div id="order-view-actions-spec-delivered">
    <div v-if="isQuoteOwner">
      <OrderViewActionCancelEdition v-if="quoteEditable" :disabled="disabled" />
      <OrderViewActionEdit v-if="!quoteEditable && !quoteSelected.quote.invoiceSent" :disabled="disabled" btn-icon="FileTextIcon" :btn-text="$t('action.recall_invoice')" />
      <b-button
        v-if="!quoteEditable && !quoteSelected.quote.invoiceSent"
        id="order-view-actions-spec-delivered-extend-invoice"
        v-ripple.400
        variant="primary"
        class="mb-75"
        block
        :disabled="disabled"
        @click="newQuote()"
      >
        <feather-icon icon="PlusIcon" size="20" class="align-middle" />
        <span class="ml-25 align-middle"> {{ $t('action.extend_invoice') }} </span>
      </b-button>
    </div>
  </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'
import { postTripStepEventCustomQuoteRequest } from '@/request/globalApi/requests/tripStepEventRequests'

export default {
  name: 'OrderViewActionsSpecDelivered',

  components: {
    OrderViewActionEdit: () => import('@/views/order/OrderViewActionEdit.vue'),
    OrderViewActionCancelEdition: () => import('@/views/order/OrderViewActionCancelEdition.vue'),
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'isQuoteOwner']),
    ...mapFields('quote', ['quoteEditable']),
  },

  methods: {
    newQuote() {
      postTripStepEventCustomQuoteRequest({ id: this.quoteSelected.checkout.stepEventId }).then(response => {
        this.$router.push({ name: 'OrderViewShow', params: { id: response.data.id, quoteEditable: true } })
      })
    },
  },
}
</script>
